import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import Utility from "../../../shared/utility.js";
import addressComp from "../address-comp.vue";
import salesService from "./sales-order-service.js";
import moment from "moment";
import salesOrderEditService from "./sales-order-edit-service.js";
import { bus } from "../../../main.js";
import { Object } from "core-js";
import BackToTop from "../../common/back-to-top.vue";
import commonApiService from "../../common/js/common-api-service.js";
export default {
  data() {
    return {
      route: "",
      disableHoldBtn: false,
      showReturnAddDDL: false,
      notRequired: false,
      disablePartShip:false,
      showLinkDialog: false,
      expectedDelete: false,
      enableEdit: true,
      expectedEdit: false,
      disableSignature: false,
      disableSatDel: false,
      showNewHireDate: true,
      cancelMsg: false,
      disableBuildQty: true,
      convertMsg: false,
      build_id: 0,
      partShipSearchKey: "",
      hold: false,
      showHold: false,
      selectedReturn: false,
      selectedShip: false,
      showStockStatus: false,
      showHoldBtn: false,
      expectedUpdate: false,
      showOrderedDate: true,
      showShipByDate: true,
      displayData: "",
      receivedDataList: [],
      stockMessage: "",
      minDate: moment(String(new Date())).format("YYYY-MM-DD"),
      deleteReasonRule: [
        (v) => !!v || "Field is required",
        (v) => /^(?=.*\S).+$/.test(v) || "Please enter at least one text or number",
        (v) => (v || "").length <= 500 || "Max Length must be 500 characters or less",
      ],
      rmaValidataion: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?:[ A-Za-z0-9\s\S]){3,25}$/.test(v) ||
          "Please enter at least one text or number, RMA should be greater than 3 characters and less than 25 characters",
      ],
      qtyRule: [
        (v) => !!v || "Field is required",
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      greaterThanRule: [],
      required: [(v) => !!v || "Field is required"],
      line_required: [(v) => parseInt(v) >= 0 || "Field is required"],
      fobValidation: [(v) => /^[ A-Za-z0-9_@!%$^*()={}|;:<>,?/./#&+-]*$/.test(v) || "Characters are not allowed"],
      shippingReq: false,
      addressRetRule: [(v) => !!v || "Value is not selected", this.selectedReturn || "Value is not selected"],
      addressShipRule: [(v) => !!v || "Value is not selected", this.selectedShip || "Value is not selected"],
      returnReq: false,
      priceValid: [
        (v) => parseInt(v) >= 0 || "Field is required",
        (v) => /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/.test(v) || "Upto 2 decimal places are allowed",
      ],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      mainPage: "",
      favouriteList: [],
      shippedDataList: [],
      showDialogDetailsBox: false,
      showOutboundLog: false,
      greaterThanValidRet: false,
      greaterValRet: "",
      greaterThanRuleRet: [],
      pageUrl: "",
      subPage: "",
      selectedLink: "",
      showAdd: false,
      releaseMsg: false,
      holdMsg: false,
      forceMsg: false,
      subPageUrl: "",
      showbuildDelete: false,
      showbuildEdit: false,
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      pickedDataList: [],
      projectList: [],
      businessList: [],
      deleteReasonDialog: false,
      cancelReason: "",
      orderTypeList: [],
      custSearchKey: "",
      build_Added: false,
      customerList: [],
      shipList: [],
      returnCount: 0,
      shipCount: 0,
      returnList: [],
      disablePartRecieve: false,
      showActionsList: [],
      bookmark: false,
      showLineDelete: false,
      showCustomerDialog: false,
      showPartShipDetails: false,
      buildPartNumList: false,
      disableBuildSave: true,
      showPartRetDetails: false,
      showEditShipToDialog: false,
      showShipToDialog: false,
      showReturnToDialog: false,
      showEditReturnToDialog: false,
      deleteBuildDialog: false,
      showEditBuild: false,
      greaterThanValid: false,
      greaterVal: 0,
      shipPartKey: "",
      final_submit: false,
      showForceDialog: false,
      showForceBtn: true,
      editPartQty: "",
      editPartDesc: "",
      editSerialNum: "",
      editAssetTag: "",
      editProblemDesc: "",
      editWarrentyStatus: "",
      editTrack: "",
      editReturn_carrier: "",
      shipSearchKey: "",
      returnSearchKey: "",
      outboundList: [],
      outboundData: [],
      newHire: false,
      tempOrderLineBackup: {},
      flexHeader: [
        {
          text: "Flex Attribute",
          align: "start",
          value: "flex_code",
          class: "primary customwhite--text",
        },
        {
          text: "Flex Value",
          value: "flex_data",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      headersLineItems: [
        {
          text: "Line#",
          align: "start",
          value: "sequence_number",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "order_line_text",
          class: "primary customwhite--text",
        },
        {
          text: "",
          value: "ouboundPresent",
          class: "primary customwhite--text",
        },
        {
          text: "Part Number To Ship",
          value: "sh_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Return Part Number",
          value: "return_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Warehouse",
          value: "sh_from_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Warehouse",
          value: "return_to_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Ordered Qty",
          value: "quantity",
          class: "primary customwhite--text",
        },
        { text: "Received", value: "Rcvd", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        {
          text: "Shipped",
          value: "Shipped",
          class: "primary customwhite--text",
        },
        {
          text: "Open Shipments",
          value: "Open_Shipments",
          class: "primary customwhite--text",
        },
        {
          text: "Available",
          value: "Quantity",
          class: "primary customwhite--text",
        },
        {
          text: "Allocated",
          value: "Allocated",
          class: "primary customwhite--text",
        },
        {
          text: "Open Returns",
          value: "Open_Receipts",
          class: "primary customwhite--text",
        },
        {
          text: "Has BTO",
          value: "build_order_ok",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
        },
      ],
      headersWarrenty: [
        {
          text: "Serial No",
          align: "start",
          value: "serial_number",
          class: "primary customwhite--text",
        },
        {
          text: "Asset Tag",
          value: "asset_tag",
          class: "primary customwhite--text",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          class: "primary customwhite--text",
        },
        {
          text: "Tracking",
          value: "return_tracking_number",
          class: "primary customwhite--text",
        },
        {
          text: "Return Carrier",
          value: "return_carrier",
          class: "primary customwhite--text",
        },
        {
          text: "Warranty",
          value: "warranty_status",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      headersBuild: [
        {
          text: "Part Number",
          align: "start",
          value: "bo_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Part Description",
          value: "bo_part_description",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "bo_qty", class: "primary customwhite--text" },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      headersPicked: [
        {
          text: "SO Line#",
          align: "start",
          value: "SO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial No#",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        {
          text: "From Bin(LPN)",
          value: "From Bin (LPN)",
          class: "primary customwhite--text",
        },
        { text: "To LPN", value: "To LPN", class: "primary customwhite--text" },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
      ],
      headersReceived: [
        {
          text: "SO Line#",
          align: "start",
          value: "SO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Receiver#",
          value: "Receiver#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "Date", value: "Date", class: "primary customwhite--text" },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill#",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Credit", value: "Credit", class: "primary customwhite--text" },
        {
          text: "Extra Credit",
          value: "Ext Credit",
          class: "primary customwhite--text",
        },
      ],
      headersShipped: [
        {
          text: "SO Line#",
          align: "start",
          value: "SO Line#",
          class: "primary customwhite--text",
        },
        {
          text: "Shipper#",
          value: "Shipper#",
          class: "primary customwhite--text",
        },
        {
          text: "Part No",
          value: "Part No",
          class: "primary customwhite--text",
        },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        {
          text: "Serial",
          value: "Serial#",
          class: "primary customwhite--text",
        },
        { text: "ESN", value: "ESN", class: "primary customwhite--text" },
        { text: "shipped", value: "Date", class: "primary customwhite--text" },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Packing Slip",
          value: "Packing Slip",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "Waybill",
          class: "primary customwhite--text",
        },
        {
          text: "Return Waybill#",
          value: "Rtn Waybill",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "Qty", class: "primary customwhite--text" },
        { text: "Credit", value: "Credit", class: "primary customwhite--text" },
        {
          text: "Extra Credit",
          value: "Ext Credit",
          class: "primary customwhite--text",
        },
      ],

      inboundDetailsHeaders: [
        {
          text: "Process Type",
          align: "start",
          value: "process_type",
          class: "primary customwhite--text",
        },
        {
          text: "Request",
          value: "request",
          class: "primary customwhite--text",
        },
        // { text: "XML", value: "request_xml", class: "primary customwhite--text" },
        {
          text: "Record Date",
          value: "created_date",
          class: "primary customwhite--text",
        },
      ],
      outboundDetailsHeaders: [
        {
          text: "Process Type",
          align: "start",
          value: "process_type",
          class: "primary customwhite--text",
        },
        {
          text: "Request",
          value: "request",
          class: "primary customwhite--text",
        },
        {
          text: "Record Date",
          value: "created_date",
          class: "primary customwhite--text",
        },
        {
          text: "Response",
          value: "response",
          class: "primary customwhite--text",
        },
        {
          text: "Sent Date",
          value: "retrieved",
          class: "primary customwhite--text",
        },
        {
          text: "Status",
          value: "msg_status",
          class: "primary customwhite--text",
        },
      ],

      // inboundDetailsHeaders: [
      //   { text: "Process Type", align: "start", value: "process_type", class: "primary customwhite--text" },
      //   { text: " Json", value: "request_json", class: "primary customwhite--text" },
      //   { text: " XML", value: "request_xml", class: "primary customwhite--text" },
      //   { text: "Created Date", value: "created_date", class: "primary customwhite--text" },

      // ],

      //   outboundDetailsHeaders:
      //     [
      //       { text: "Sent Date", align: "start", value: "retrieved", class: "primary customwhite--text" },
      //       { text: "Response", value: "response", class: "primary customwhite--text" },
      //       { text: "Status", value: "msg_status", class: "primary customwhite--text" },

      //     ],
      ouboundLogHeader: [
        {
          text: "Client Response Json",
          align: "start",
          value: "client_response_json",
          class: "primary customwhite--text",
        },
        {
          text: "Retrieved",
          value: "retrieved",
          class: "primary customwhite--text",
        },
      ],
      inboundList: [],
      inboundDataList: [],
      outboundDataList: [],
      panel: [0, 1, 2, 3, 4],
      salesOrderId: "",
      edit_line_num: "",
      expected_serial_number_list: [],
      lineItemPanel: [0, 1],
      showFlexDialog: false,
      showConevertDropDown: false,
      flex_field_Att: "",
      flex_value: "",
      showPartsItem: false,
      showBookMark: false,
      lineTypeList: [],
      disableLine: false,
      disableQty: false,
      showPartShip: false,
      showPartRet: false,
      showFlex: false,
      disableRetQty: false,
      partNumbertoShip: "",
      partResultList: [],
      showPartDetails: false,
      showBuildIcon: false,
      showBuildExpand: false,
      selectedShipFromWare: "",
      shipWareList: [],
      convertedList: [],
      partBuildPartNum: "",
      buildPartResultList: [],
      buildToQty: "",
      returnWareList: [],
      partReturnSearchKey: "",
      returnResultList: [],
      returnPartNum: "",
      returnPartDesc: "",
      showexpIcon: false,
      showExpectedSr: false,
      soDelete: false,
      serial_num: "",
      asset_tag: "",
      tracking_num: "",
      warr_status: "N",
      returnCarrier: "",
      temp_waybill_id: 1,
      buildOrderList: [],
      salesOrderDetails: "",
      refreshDialog: false,
      isEditing: true,
      flexDisplayList: [],
      // SO JSON
      salesOrderJson: {
        so_id: "",
        request_number: "",
        order_number: "",
        proj_id: "",
        bu_id: "",
        inbound_status: "",
        rma: "",
        ordered_date: "",
        outbound_status: "",
        customer_number: "",
        requested_ship_date: "",
        order_type: "",
        order_type_id: "",
        customer_po: "",
        customer_order_number: "",
        fob: "",
        pay_term_id: "",
        ritm: "",
        shipment_terms: "",
        employee_name: "",
        employee_id: "",
        new_hire: false,
        new_hire_date: "",
        CustomerInfo: [
          {
            title: "",
            last: "",
            cust_id: "",
            custno: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            fax: "",
            email: "",
          },
        ],
        shipment_address: [
          {
            sh_title: "",
            sh_first: "",
            sh_last: "",
            sh_custno: "",
            sh_company: "",
            sh_address1: "",
            sh_address2: "",
            sh_city: "",
            sh_state: "",
            sh_zip: "",
            sh_country: "",
            sh_phone1: "",
            sh_phone2: "",
            sh_cell: "",
            sh_fax: "",
            sh_email: "",
            sh_cust_id: "",
          },
        ],
        return_address: [
          {
            rt_title: "",
            rt_first: "",
            rt_last: "",
            rt_comp_id: "",
            rt_custno: "",
            rt_company: "",
            rt_address1: "",
            rt_address2: "",
            rt_city: "",
            rt_state: "",
            rt_zip: "",
            rt_country: "",
            rt_phone1: "",
            rt_phone2: "",
            rt_cell: "",
            rt_fax: "",
            rt_email: "",
          },
        ],
        shipping_priority: "",
        return_shipping_priority: "",
        signature_required: false,
        saturday_delivery: false,
        order_notes: "",
        problem_description: "",
        so_notes: "",
        flex_field: [],
        order_list: [],
        order_line: [],
      },
      //TEMPJSON
      temp_order_line: {
        sequence_number: "",
        order_line_type_text: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_price: 0.0,
        build_orders: {
          build_order_notes: "",
          build_order: [],
        },
        return_part_id: "",
        return_part_number: "",
        return_part_description: "",
        return_to_warehouse_id: "",
        return_to_warehouse: "",
        return_to_inventory_bucket: "",
        return_quantity: "",
        return_price: "",
        return_rma: "",
        expected_serial_number: [],
        line_comment: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: "",
        showPartShip: "",
        showBuild: "",
        showSerial: "",
        quantity: 0,
        Allocated: 0,
        Picked: 0,
        shipped: 0,
      },
      soHistory: true,
      salesOrderHistoryItems: [],
      salesOrderHistoryDialog: false,
      headersSalesOrderHistory: [
        {
          text: "Action",
          align: "start",
          value: "Action",
          class: "primary customwhite--text",
        },
        { text: "Logged", value: "Logged", class: "primary customwhite--text" },
        { text: "User", value: "User", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    //to Load Data
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data?.actionList;
    let actionData = await AddFavourite.getParentPageAction(this.userId, "sales-order-details"); // URL hard coded
    let pagePermissions = actionData?.actionList;
    pagePermissions?.forEach((element) => {
      this.actionList.push(element);
    });
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    // to load the Project Dropdown
    let projectData = await commonApiService.getProject("get", this.userId);
    this.projectList = projectData?.ProjectInfo;
    this.salesOrderId = atob(this.$route.params.salesOrderId);
    this.salesOrderDetails = await salesOrderEditService.getSalesOrderDetails("get", this.userId, this.salesOrderId);
    // Assigning the values we get from API to the Form JSON we created
    this.convertTime()

    this.getInboundGridData();
    this.getOutboundGridData();
    this.showHoldBtn = Boolean(this.salesOrderJson.enable_hold);
    this.returnSearchKey = this.salesOrderJson.return_address[0].rt_company;
    if (this.returnSearchKey) this.selectedReturn = true;
    this.salesOrderJson.order_list?.forEach((v) => {
      v.isEdited = false;
    });
    this.bookmark = Boolean(this.salesOrderJson.is_bookmark);
    this.showActionsList.push({
      Id: "SO-History",
      Icon: "mdi mdi-account-clock-outline",
      FriendlyName: "SO History",
    });
    //Actions for Hold
    if (this.showHoldBtn) {
      if (this.hold)
        this.showActionsList.push({
          Id: "SO-Release",
          Icon: "mdi mdi-play-outline",
          FriendlyName: "SO Release",
        });
      else
        this.showActionsList.push({
          Id: "SO-Hold",
          Icon: "mdi mdi-pause-box-outline",
          FriendlyName: "SO Hold",
        });
    }
    if (this.bookmark)
      this.showActionsList.push({
        Id: "SO-BookMark-Remove",
        Icon: "mdi mdi-bookmark-outline",
        FriendlyName: "Remove Bookmark",
      });
    else
      this.showActionsList.push({
        Id: "SO-BookMark",
        Icon: "mdi mdi-bookmark",
        FriendlyName: "Add Bookmark",
      });
    this.salesOrderJson.so_id = this.salesOrderId;
    this.flexDisplayList = JSON.parse(JSON.stringify(this.salesOrderJson.flex_field));
    // Business API Call
    let projObj = {
      UserId: this.userId,
      ProjKey: this.salesOrderJson.proj_id,
      BuKey: 0,
      IncludeEmptyBU: 0,
      WithinSameLocation: 1, // Do not Delete
      loca_key: 0,
    };
    let businessData = await commonApiService.postBusinessData("post", projObj);
    this.businessList = businessData?.map((x) => ({
      bu_id: x.Bu_id,
      business: x.Business,
    }));
    // API CALL for Order Type
    let businessObj = {
      UserId: this.userId,
      bu_key: this.salesOrderJson.bu_id,
      pass: 1,
    };
    let orderTypeData = await salesOrderEditService.postOrderTypeData("post", businessObj);
    if (orderTypeData?.OrderTypes) {
      this.notRequired = true;
      this.orderTypeList = orderTypeData.OrderTypes?.map((x) => ({
        ord_code: x.ord_code,
        ordCodedesc: x.ordCodedesc,
      }));
    } else {
      this.notRequired = false;
    }
    this.LineTypeData();
    this.paymentList = orderTypeData?.PaymentTerms;
    // Warehouse List
    // Carrier API
    let carrierObj = {
      UserId: this.userId,
      bu_key: this.salesOrderJson.bu_id,
      custpo: this.salesOrderJson.customer_po,
      order_type: this.salesOrderJson.order_type,
    };
    let carrierData = await salesOrderEditService.postcarrierServiceLvlData(carrierObj);
    this.outboundList = carrierData?.OutboundSLs;
    this.inboundList = carrierData?.InboundSLs;
    this.inboundDataList?.forEach((element) => {
      element.created_date = Utility.convertESTToLocal(element.created_date);
    });

    this.shipSearchKey = this.salesOrderJson.shipment_address[0]?.sh_company;
    if (this.shipSearchKey) this.selectedShip = true;
    this.custSearchKey = this.salesOrderJson.CustomerInfo[0]?.company;
    LoaderDialog.visible = false;
    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    this.setActionFunction();
  },
  // For Reload Functionality
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  // To Check if router is changed
  beforeRouteLeave(to, from, next) {
    let soBackup = JSON.parse(JSON.stringify(this.salesOrderDetails.Result[0]));
    soBackup.order_list?.forEach((v) => {
      v.isEdited = false;
    });
    soBackup.so_id = String(soBackup.so_id);
    if (JSON.stringify(soBackup) != JSON.stringify(this.salesOrderJson)) {
      if (!this.final_submit) this.isEditing = true;
      else this.isEditing = false;
    } else {
      this.isEditing = false;
    }
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.salesOrderJson.ordered_date
        ? moment(String(this.salesOrderJson.ordered_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipcomputeDateFormattedMomentjs() {
      return this.salesOrderJson.requested_ship_date ? moment(String(this.salesOrderJson.requested_ship_date)).format("MM/DD/YYYY") : "";
    },
    newHireComputedDateFormattedMomentjs() {
      return this.salesOrderJson.new_hire_date ? moment(this.salesOrderJson.new_hire_date).format("MM/DD/YYYY") : "";
    },
  },
  mounted() { },
  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event)
        return value
      }
      else {
        let value = Utility.isNumberWithPeriod(event)
        return value
      }
    },
    //Action List
    setActionFunction() {
      if (this.actionSetter("DeleteSalesOrder"))
        this.showActionsList.push({
          Id: "SO-Delete",
          Icon: "mdi-delete",
          FriendlyName: "SO Delete",
        }),
          this.showActionsList.push({
            Id: "SO-Reset",
            Icon: "mdi-refresh",
            FriendlyName: "Reset",
          });
    },
    // Action based
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList?.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Sales Order History button click event
    async salesOrderHistory() {
      let historyData = await salesService.getSalesOrderActionLog(this.salesOrderId, this.userId);
      if (historyData !== null && historyData !== undefined) {
        const newArr = historyData.ActionLogInfo?.map((obj) => {
          return {
            ...obj,
            Logged: Utility.convertESTToLocal(obj.Logged),
          };
        });
        this.salesOrderHistoryItems = newArr;
        this.salesOrderHistoryDialog = true;
      }
    },
    //Yes button on the refresh popup click event
    refreshYesClick() {
      this.refreshDialog = false;
    },
    //No button on the refresh popup click event
    refreshNoClick() {
      this.refreshDialog = false;
    },
    //Get Customer Data
    async getCustomerData() {
      if (this.custSearchKey) {
        this.custSearchKey = this.custSearchKey?.toUpperCase();
        if (this.custSearchKey.length > 2) {
          let obj = {
            UserId: parseInt(this.userId),
            proj_key: this.salesOrderJson.proj_id,
            SearchStr: this.custSearchKey,
            pass: 1, // DO not Delte
          };
          let resp = await salesOrderEditService.postCustomerData("post", obj, false, true);
          if (resp?.CustomerInfo) {
            this.customerList = resp.CustomerInfo?.map((x) => ({
              title: x.title,
              last: x.first + " " + x.last,
              cust_id: x.cust_id,
              custno: x.custno,
              company: x.company,
              address1: x.address1,
              address2: x.address2,
              city: x.city,
              state: x.state,
              zip: x.zip,
              country: x.country,
              phone1: x.phone1,
              phone2: x.phone2,
              cell: x.cell,
              email: x.email,
              fax: x.fax,
            }));
          }
          this.salesOrderJson.CustomerInfo = [
            {
              first: "",
              title: "",
              last: "",
              cust_id: "",
              custno: "",
              company: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
              country: "",
              phone1: "",
              phone2: "",
              cell: "",
              fax: "",
              email: "",
            },
          ];
        } else {
          this.clearCustAddressData();
        }
      }
    },
    //Clear Customer address data
    clearCustAddressData() {
      this.salesOrderJson.CustomerInfo =
      {
        first: "",
        title: "",
        last: "",
        cust_id: "",
        custno: "",
        company: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone1: "",
        phone2: "",
        cell: "",
        fax: "",
        email: "",
      },
        this.customerList = [];
      this.showAddress = false;
    },
    // Parent Nav function
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    //TO show Customer Data
    showCustomer(item) {
      Object.assign(this.salesOrderJson.CustomerInfo[0], item);
      this.custSearchKey = item.company;
      this.customerList = [];
    },
    // To get the Ship
    async getShipData() {
      if (this.shipSearchKey) {
        this.shipSearchKey = this.shipSearchKey?.toUpperCase();
        if (this.shipSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.salesOrderJson.proj_id,
            SearchStr: this.shipSearchKey,
            pass: 2, // Do not Delete
          };
          let resp = await salesOrderEditService.postCustomerData("post", obj, false, true);
          if (resp?.CustomerInfo) {
            this.shipList = resp.CustomerInfo?.map((x) => ({
              sh_address1: x.sh_address1,
              sh_address2: x.sh_address2,
              sh_cell: x.sh_cell,
              sh_city: x.sh_city,
              sh_company: x.sh_company,
              sh_country: x.sh_country,
              sh_custno: x.sh_custno,
              sh_cust_id: x.sh_cust_id,
              sh_email: x.sh_email,
              sh_fax: x.sh_fax,
              sh_first: x.sh_first,
              sh_last: x.sh_last,
              sh_phone1: x.sh_phone1,
              sh_phone2: x.sh_phone2,
              sh_state: x.sh_state,
              sh_title: x.sh_title,
              sh_zip: x.sh_zip,
            }));
          }
        } else {
          this.clearShipAddressData();
        }
      }
    },
    //clear Ship address
    clearShipAddressData() {
      this.shipList = [];
      this.showShipAddress = false;
      this.sameAsCust = false;
    },
    // On part Number to Return
    onPartReturnChange(warehouse_id) {
      let returnShipObj = this.returnWareList?.filter((x) => x.ware_id == warehouse_id);
      this.temp_order_line.return_to_inventory_bucket = returnShipObj[0].bucket;
      this.temp_order_line.return_to_warehouse = returnShipObj[0].ware;
      this.temp_order_line.return_to_warehouse_id = returnShipObj[0].ware_id;
    },
    // to show Address
    showShip(item) {
      Object.assign(this.salesOrderJson.shipment_address[0], item);
      this.shipSearchKey = item.sh_company;
      if (this.shipSearchKey) this.selectedShip = true;
      this.shipList = [];
    },
    // To get Return Data
    async getReturnData() {
      if (this.returnSearchKey) {
        this.returnSearchKey = this.returnSearchKey?.toUpperCase();
        if (this.returnSearchKey.length > 2) {
          let returnData = await salesOrderEditService.getReturnAddData(
            "get",
            this.userId,
            this.salesOrderJson.proj_id,
            this.returnSearchKey,
            this.salesOrderJson.bu_id,
            "",
            false,
            true,
            1
          );
          if (returnData?.ReturnAddress) this.showReturnAddDDL = true;
          this.returnList = returnData.ReturnAddress?.map((x) => ({
            rt_address1: x.rt_address1,
            rt_address2: x.rt_address2,
            rt_cell: x.rt_cell,
            rt_city: x.rt_city,
            rt_company: x.rt_company,
            rt_country: x.rt_country,
            rt_custno: x.rt_custno,
            rt_comp_id: x.rt_comp_id,
            rt_email: x.rt_email,
            rt_fax: x.rt_fax,
            rt_first: x.rt_first,
            rt_last: x.rt_last,
            rt_phone1: x.rt_phone1,
            rt_phone2: x.rt_phone2,
            rt_state: x.rt_state,
            rt_title: x.rt_title,
            rt_zip: x.rt_zip,
          }));
        } else {
          this.clearReturnAddressData();
        }
      }
    },
    // to show Address
    showReturn(item) {
      this.showReturnAddress = true;
      Object.assign(this.salesOrderJson.return_address[0], item);
      this.returnSearchKey = item.rt_company;
      this.selectedReturn = true;
      this.$refs.addressForm?.resetValidation();

      this.returnList = [];
    },
    //clear Ship address
    clearReturnAddressData() {
      this.returnList = [];
      this.showReturnAddress = false;
    },

    //Getting the data back from Compoents based on val flag we will call the respsective APIs and but the address
    async addAddress(custObj, val) {
      if (val == "customer") {
        {
          let obj = {
            CuJson: JSON.stringify(custObj),
            pass: 1, //Do not remove
          };
          let res = await salesService.postAddCustomer("post", obj, true);
          Object.assign(this.salesOrderJson.CustomerInfo[0], custObj);
          this.salesOrderJson.CustomerInfo[0].cust_id = res.Result[0].Cust_Id; // DO not Remove
        }
        this.showCustomerDialog = false;
        this.showAddress = true;
      }
      // new ship is added
      else if (val == "ship") {
        let obj = {
          CuJson: JSON.stringify(custObj),
          pass: 2, // DO not Remove
        };
        let res = await salesService.postAddCustomer("post", obj, true);
        this.salesOrderJson.shipment_address[0].cust_id = res.Result[0].Cust_Id;
        this.salesOrderJson.shipment_address[0].sh_title = custObj.title;
        this.salesOrderJson.shipment_address[0].sh_first = "";
        this.salesOrderJson.shipment_address[0].sh_last = custObj.first + " " + custObj.last;
        this.salesOrderJson.shipment_address[0].sh_cust_id = custObj.cust_id;
        this.salesOrderJson.shipment_address[0].sh_custno = custObj.custno;
        this.salesOrderJson.shipment_address[0].sh_company = custObj.company;
        this.salesOrderJson.shipment_address[0].sh_address1 = custObj.address1;
        this.salesOrderJson.shipment_address[0].sh_address2 = custObj.address2;
        this.salesOrderJson.shipment_address[0].sh_city = custObj.city;
        this.salesOrderJson.shipment_address[0].sh_state = custObj.state;
        this.salesOrderJson.shipment_address[0].sh_zip = custObj.zip;
        this.salesOrderJson.shipment_address[0].sh_country = custObj.country;
        this.salesOrderJson.shipment_address[0].sh_phone1 = custObj.phone1;
        this.salesOrderJson.shipment_address[0].sh_phone2 = custObj.phone2;
        this.salesOrderJson.shipment_address[0].sh_cell = custObj.cell;
        this.salesOrderJson.shipment_address[0].sh_email = custObj.email;
        this.salesOrderJson.shipment_address[0].sh_fax = custObj.fax;
        this.showShipToDialog = false;
      }
      // if new return is addes
      else if (val == "return") {
        let obj = {
          CuJson: JSON.stringify(custObj),
        };
        let returnres = await salesService.postAddReturnAddress("post", obj, true);
        this.salesOrderJson.return_address[0].comp_id = returnres.Result[0].comp_id; // DO not delete
        this.salesOrderJson.return_address[0].rt_title = custObj.title;
        this.salesOrderJson.return_address[0].rt_first = "";
        this.salesOrderJson.return_address[0].rt_last = custObj.first + " " + custObj.last;
        this.salesOrderJson.return_address[0].rt_comp_id = custObj.cust_id;
        this.salesOrderJson.return_address[0].rt_custno = custObj.custno;
        this.salesOrderJson.return_address[0].rt_company = custObj.company;
        this.salesOrderJson.return_address[0].rt_address1 = custObj.address1;
        this.salesOrderJson.return_address[0].rt_address2 = custObj.address2;
        this.salesOrderJson.return_address[0].rt_city = custObj.city;
        this.salesOrderJson.return_address[0].rt_state = custObj.state;
        this.salesOrderJson.return_address[0].rt_zip = custObj.zip;
        this.salesOrderJson.return_address[0].rt_country = custObj.country;
        this.salesOrderJson.return_address[0].rt_phone1 = custObj.phone1;
        this.salesOrderJson.return_address[0].rt_phone2 = custObj.phone2;
        this.salesOrderJson.return_address[0].rt_cell = custObj.cell;
        this.salesOrderJson.return_address[0].rt_email = custObj.email;
        this.salesOrderJson.return_address[0].rt_fax = custObj.fax;
        this.showReturnToDialog = false;
        this.showReturnAddress = true;
      }
      // If user edits the address for just this order
      else if (val == "shipEdit") {
        this.salesOrderJson.shipment_address[0].sh_title = custObj.title;
        this.salesOrderJson.shipment_address[0].sh_first = "";
        this.salesOrderJson.shipment_address[0].sh_last = custObj.first + " " + custObj.last;
        this.salesOrderJson.shipment_address[0].sh_cust_id = custObj.cust_id;
        this.salesOrderJson.shipment_address[0].sh_custno = custObj.custno;
        this.salesOrderJson.shipment_address[0].sh_company = custObj.company;
        this.salesOrderJson.shipment_address[0].sh_address1 = custObj.address1;
        this.salesOrderJson.shipment_address[0].sh_address2 = custObj.address2;
        this.salesOrderJson.shipment_address[0].sh_city = custObj.city;
        this.salesOrderJson.shipment_address[0].sh_state = custObj.state;
        this.salesOrderJson.shipment_address[0].sh_zip = custObj.zip;
        this.salesOrderJson.shipment_address[0].sh_country = custObj.country;
        this.salesOrderJson.shipment_address[0].sh_phone1 = custObj.phone1;
        this.salesOrderJson.shipment_address[0].sh_phone2 = custObj.phone2;
        this.salesOrderJson.shipment_address[0].sh_cell = custObj.cell;
        this.salesOrderJson.shipment_address[0].sh_email = custObj.email;
        this.salesOrderJson.shipment_address[0].sh_fax = custObj.fax;
        this.showEditShipToDialog = false;
      }
      // if Return edit is created from Address Comp
      else if (val == "returnEdit") {
        this.salesOrderJson.return_address[0].sh_title = custObj.title;
        this.salesOrderJson.return_address[0].rt_first = "";
        this.salesOrderJson.return_address[0].rt_last = custObj.first + " " + custObj.last;
        this.salesOrderJson.return_address[0].rt_cust_id = custObj.cust_id;
        this.salesOrderJson.return_address[0].rt_custno = custObj.custno;
        this.salesOrderJson.return_address[0].rt_company = custObj.company;
        this.salesOrderJson.return_address[0].rt_address1 = custObj.address1;
        this.salesOrderJson.return_address[0].rt_address2 = custObj.address2;
        this.salesOrderJson.return_address[0].rt_city = custObj.city;
        this.salesOrderJson.return_address[0].rt_state = custObj.state;
        this.salesOrderJson.return_address[0].rt_zip = custObj.zip;
        this.salesOrderJson.return_address[0].rt_country = custObj.country;
        this.salesOrderJson.return_address[0].rt_phone1 = custObj.phone1;
        this.salesOrderJson.return_address[0].rt_phone2 = custObj.phone2;
        this.salesOrderJson.return_address[0].rt_cell = custObj.cell;
        this.salesOrderJson.return_address[0].rt_email = custObj.email;
        this.salesOrderJson.return_address[0].rt_fax = custObj.fax;
        this.showEditReturnToDialog = false;
      }
    },
    // on Click open the Dialog Dialog of build order tables
    OnClickEditBuild(item, buildOrderEditShow) {
      if (buildOrderEditShow == "buildOrderEditShow") {
        this.editListFlex = false;
        this.editWaybillShow = false;
        this.showEditBuild = true;
        this.editPartQty = item.bo_qty;
        this.editPartNum = item.bo_part_number;
        this.editPartDesc = item.bo_part_description;
        this.build_id = item.build_id;
        this.buildRow = true;
      } else if (buildOrderEditShow == "editWaybillShow") {
        this.buildRow = false;
        this.editListFlex = false;
        this.editWaybillShow = true;
        this.showEditBuild = true;
        this.editSerialNum = item.serial_number;
        this.editAssetTag = item.asset_tag;
        this.sequence_number = item.sequence_number;
        this.editProblemDesc = item.problem_description;
        this.editWarrantyStatus = item.warranty_status;
        this.editTrack = item.return_tracking_number;
        this.editReturn_carrier = item.return_carrier;
        this.temp_waybill_id = item.temp_waybill_id;
      } else if (buildOrderEditShow == "editListFlex") {
        this.buildRow = false;
        this.editListFlex = true;
        this.editWaybillShow = false;
        this.showEditBuild = true;
        this.sequence_number = item.temp_flex_id;
        this.list_flex_field_Att = item.flex_code;
        this.list_flex_value = item.flex_data;
      }
    },
    // On Add Flex
    onClickFlexAdd(flex_field_Att, flex_value) {
      if (this.$refs.flexForm?.validate()) {
        let order_line_num =
          this.salesOrderJson.order_line.length == null || this.salesOrderJson.order_line.length == undefined
            ? 0
            : this.salesOrderJson.order_line.length;
        // if show parts item is true that means this add flex is for line Item
        if (this.showPartsItem) {
          let flx_ord_seq =
            this.temp_order_line.flex_field.length == undefined || this.temp_order_line.flex_field.length == null
              ? 0
              : this.temp_order_line.flex_field.length;
          let obj = {
            temp_flex_id: flx_ord_seq + 1,
            sequence_number: order_line_num + 1,
            flex_code: flex_field_Att,
            flex_data: flex_value,
            is_Added: true,
            is_Deleted: false,
          };
          this.temp_order_line.flex_field.push(obj);
        }
        // this flex is for main flex
        else {
          let flxseq =
            this.salesOrderJson.flex_field.length == undefined || this.salesOrderJson.flex_field.length == null
              ? 0
              : this.salesOrderJson.flex_field.length;
          let obj = {
            sequence_number: flxseq + 1,
            flex_code: flex_field_Att,
            flex_data: flex_value,
            soflex_id: 0,
            is_Added: true,
            is_Deleted: false,
          };
          this.salesOrderJson.flex_field.push(obj);
          this.flexDisplayList.push(obj);
        }
        this.showFlexDialog = false;
        this.flex_field_Att = "";
        this.flex_value = "";
        this.$refs.flexForm?.resetValidation();
      }
    },
    clearFlexItem() {
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_value = "";
      this.$refs.flexForm?.resetValidation();
    },
    // To Get Flex Details
    flexDetails(item) {
      this.buildRow = false;
      this.editFlex = true;
      this.editListFlex = false;
      this.editWaybillShow = false;
      this.showEditBuild = true;
      this.sequence_number = item.temp_flex_id ? item.temp_flex_id : item.sequence_number;
      this.list_flex_field_Att = item.flex_code;
      this.list_flex_value = item.flex_data;
    },
    //  Delete Flex
    deleteFlex() {
      this.temp_order_line.flex_field = this.temp_order_line?.flex_field?.filter((x) => x.temp_flex_id !== this.sequence_number);
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
    },
    // Delete Flex which is ouside line Items
    deleteJSONFlex() {
      this.salesOrderJson.flex_field.find((v) => v.sequence_number === this.sequence_number).is_Deleted = true;
      if (
        this.salesOrderJson.flex_field?.find((v) => v.sequence_number === this.sequence_number).is_Deleted &&
        this.salesOrderJson.flex_field?.find((v) => v.sequence_number === this.sequence_number).is_Added
      ) {
        this.salesOrderJson.flex_field = this.salesOrderJson.flex_field?.filter((x) => x.sequence_number !== this.sequence_number);
      }
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
      this.flexDisplayList = this.flexDisplayList?.filter((x) => x.sequence_number !== this.sequence_number);
    },
    // Update flex
    onClickUpdateFlex(list_flex_field_Att, list_flex_value) {
      if (this.$refs.EditflexForm?.validate()) {
        // if show parts item is true the it will fill json flex or else it will update line item's flex
        if (!this.showPartsItem) {
          this.flexDisplayList = this.flexDisplayList?.map((p) =>
            p.sequence_number == this.sequence_number
              ? {
                ...p,
                flex_code: list_flex_field_Att,
                flex_data: list_flex_value,
              }
              : p
          );
          this.salesOrderJson.flex_field = this.salesOrderJson.flex_field?.map((p) =>
            p.sequence_number == this.sequence_number
              ? {
                ...p,
                flex_code: list_flex_field_Att,
                flex_data: list_flex_value,
              }
              : p
          );
        } else {
          this.temp_order_line.flex_field = this.temp_order_line.flex_field?.map((p) =>
            p.temp_flex_id == this.sequence_number
              ? {
                ...p,
                flex_code: list_flex_field_Att,
                flex_data: list_flex_value,
              }
              : p
          );
        }
        this.showEditBuild = false;
      }
    },
    //close part ITem
    closePartItem() {
      this.showPartsItem = false;
      this.showLineDelete = false;
      this.showExpectedSr = false;
      this.clearPartItem();
    },
    // Clear Part Item
    clearPartItem() {
      this.lineItemPanel = [0, 1];
      this.showPartDetails = false;
      this.buildPartNumList = false;
      this.showPartShipDetails = false;
      this.showPartRetDetails = false;
      this.disableQty = false;
      this.buildOrderList = [];
      this.expected_serial_number_list = [];
      this.disableRetQty = false;
      this.showConvertBtn = false;
      this.showForceBtn = false;
      this.returnPartNum = "";
      this.returnPartDesc = "";
      this.showLineDelete = false;
      this.showConevertDropDown = false;
      this.showBuildExpand = false;
      this.showStockStatus = false;
      this.disablePartRecieve = false;
      this.stockMessage = "";
      this.serial_num = "";
      this.asset_tag = "";
      this.problem_desc = "";
      this.warr_status = "N";
      this.tracking_num = "";
      this.return_carrier = "";
      this.enableEdit = true;
      this.temp_order_line = {
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_price: (0).toFixed(2),
        build_orders: {
          build_order_notes: "",
          build_order: [],
        },
        return_part_id: "",
        return_part_number: "",
        return_part_description: "",
        return_to_warehouse_id: "",
        return_to_warehouse: "",
        return_to_inventory_bucket: "",
        return_quantity: "",
        return_price: "",
        expected_serial_number: [],
        line_comment: "",
        flex_field: [],
        is_FAllocated: false,
        is_Converted: false,
      };
      this.sameAsShip = false;
      this.shipPartName = "";
      this.partBuildPartNum = "";
      this.buildToQty = "";
      this.shipPartDesc = "";
      this.partShipSearchKey = "";
      this.partReturnSearchKey = "";
      this.disableLine = false;
      this.showPartShip = false;
      this.showPartRet = false;
      this.showFlex = false;
      this.showexpIcon = false;
      this.showBuildIcon = false;
    },
    // To clean part number once its click on cancel
    clearPartShip() {
      this.$refs?.PartShipForm?.resetValidation()
      this.shipPartName = "";
      this.shipPartDesc = "";
      this.temp_order_line.sh_price = "";
      this.temp_order_line.sh_quantity = "";
      this.temp_order_line.sh_from_warehouse_id = "";
      this.showPartDetails = false;
      this.disableQty = false;
      this.showBuildIcon = false;
      this.showStockStatus = false
      this.temp_order_line.build_orders.build_order = [];
      this.showBuildExpand = false;
      this.temp_order_line.build_orders.build_order_notes = "";
      this.shipWareList = [];
      this.temp_order_line.sh_from_warehouse = "";
      this.temp_order_line.sh_from_inventory_bucket = "";
      this.temp_order_line.sh_part_id = "";
      this.disablePartShip=false;
    },
    // Clear Part Return Items
    clearPartReturn() {
      this.$refs?.PartRecieveForm?.resetValidation()
      this.temp_order_line.return_part_number = "";
      this.temp_order_line.return_part_description = "";
      this.temp_order_line.return_price = "";
      this.temp_order_line.return_quantity = "";
      this.temp_order_line.return_to_inventory_bucket = "";
      this.temp_order_line.return_to_warehouse = "";
      this.temp_order_line.return_to_warehouse_id = "";
      this.temp_order_line.return_part_id = "";
      this.returnWareList = [];
      this.expected_serial_number = [];
    },
    // Validation based of Line Type CHeck
    validateLineType() {
      //User to find Unique items in an array;
      let lineItemUsedObj = this.salesOrderJson.order_list.reduce((acc, cur) => {
        acc[cur.order_line_type] = true;
        return acc;
      }, {});
      let lineTypeListObj = this.lineTypeList.reduce((acc, cur) => {
        acc[cur.so_type_id] = cur;
        return acc;
      }, {});
      Object.keys(lineTypeListObj)?.forEach((key) => {
        if (lineItemUsedObj[key]) {
          this.shippingReq = this.shippingReq || Boolean(lineTypeListObj[key].to_ship);
          this.returnReq = this.returnReq || !!lineTypeListObj[key].to_return;
        }
      });
    },
    //New Line Item
    async LineTypeData() {
      let LineTypeData = await salesOrderEditService.getOrderTypeData("get", this.userId, this.salesOrderJson.proj_id, this.salesOrderId);
      if (LineTypeData?.LineTypeInfo) {
        this.lineTypeList = LineTypeData.LineTypeInfo?.map((x) => ({
          so_type_id: x.sotype_id,
          so_desc: x.so_desc,
          so_type: x.so_type,
          to_ship: x.to_ship,
          to_return: x.to_return,
          to_disable_quantity: x.to_disable_quantity,
          to_disable_ReturnSel: x.to_disable_ReturnSel,
        }));
        this.validateLineType();
        this.outboundOrderLine();
      }
      if (LineTypeData?.ConvertLineInfo) {
        this.convertedList = LineTypeData?.ConvertLineInfo?.filter((x) => x.sotype_id == this.temp_order_line.order_line_type);
        if (
          this.convertedList.length > 0 &&
          !this.temp_order_line.is_Converted &&
          !this.temp_order_line.is_FAllocated &&
          this.temp_order_line.showConvertBtn
        ) {
          this.temp_order_line.showConvertBtn = true;
        } else {
          this.temp_order_line.showConvertBtn = false;
        }
      }
    },
    // To get warrenty Data
    async warrentyData() {
      let warrentyListData = await salesOrderEditService.getWarrentyData("get", this.userId);
      if (warrentyListData != "") {
        this.warrentyList = warrentyListData.WarrantyData;
      }
    },
    // On click of Add List
    async onClickAddList() {
      this.disableQty = true;
      this.enableEdit = false;
      this.temp_order_line.enable_save=false
      this.LineTypeData();
      this.showPartsItem = true;
      this.clearPartItem();
      this.temp_order_line.is_FAllocated = true;
      this.warrentyData();
    },
    //Get Part Number Ship Data
    //Get Ship  Data
    async partShipData() {
      if (this.partShipSearchKey) {
        this.clearPartShip()
        this.partShipSearchKey = this.partShipSearchKey?.toUpperCase();
        if (this.partShipSearchKey.length > 2) {
          this.showPartShipDetails = true;
          let shipDataObj = {
            UserId: this.userId,
            SearchStr: this.partShipSearchKey,
            BuKey: this.salesOrderJson.bu_id,
            order_type: this.salesOrderJson.order_type,
          };
          let shipDataSearch = await salesOrderEditService.postShipSearch("post", shipDataObj, false, true);
          if (shipDataSearch?.Resultset) this.partResultList = shipDataSearch.Resultset;
          else this.clearPartShip()

        }
        else {
          this.clearPartShip()
        }
      }
    },
    //Show Part Number Details
    async showPartDesc(item) {
      this.partResultList = [];
      this.showPartDetails = true;
      this.showBuildExpand = false;
      this.buildOrderList = [];
      this.temp_order_line.build_orders.build_order = [];
      this.temp_order_line.sh_part_id = item.part_id;
      this.temp_order_line.sh_price = (0).toFixed(2);
      this.temp_order_line.sh_part_number = item.part_num;
      this.temp_order_line.sh_part_description = item.part_desc;
      this.shipPartName = item.part_num;
      this.partShipSearchKey = item.part_num;
      this.shipPartDesc = item.part_desc;
      this.shipPartKey = item.part_id;
      let shipWareObj = {
        part_num: item.part_num,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id: 0, //Do not Delete
        so_key: 0, //DO not Delete
        UserId: this.userId,
      };
      this.disableQty = false;
      let shipWarehouseList = await salesService.postShipWareData("post", shipWareObj);
      this.showBuildIcon = shipWarehouseList.Result2[0].enable_build_sheet;
      this.showexpIcon = shipWarehouseList.Result2[0].enable_expected_sn;
      // this.showBuildIcon = true // DO no Delete
      this.temp_order_line.showBuild = this.showBuildIcon;
      if (this.showLineDelete) {
        this.temp_order_line.sh_price = parseInt(shipWarehouseList.Result1[0].defaultprice);
      }
      this.temp_order_line.showSerial = this.showexpIcon;
      if (shipWarehouseList?.ShipFromWare) {
        this.shipWareList = shipWarehouseList.ShipFromWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          from_warebuck: x.from_warebuck,
          mess_bit: x.mess_bit,
          show_text: x.show_text,
        }));
      }
      if (shipWarehouseList?.ReturnToWare != "") {
        this.returnWareList = shipWarehouseList.ReturnToWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          into_warebuck: x.into_warebuck,
        }));
        if (this.disablePartRecieve) {
          this.partReturnSearchKey = this.partShipSearchKey;
          this.temp_order_line.return_part_number = this.temp_order_line.sh_part_number;
          this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity;
          this.temp_order_line.return_part_description = this.temp_order_line.sh_part_description;
          this.temp_order_line.return_part_id = this.temp_order_line.sh_part_id;
        }
      }
    },
    //Part Number for Build Order
    async buildPartNum() {
      if (this.partBuildPartNum) {
        this.partBuildPartNum = this.partBuildPartNum?.toUpperCase();
        if (this.partBuildPartNum.length > 2) {
          this.buildPartNumList = true;
          let shipDataObj = {
            UserId: this.userId,
            SearchStr: this.partBuildPartNum,
            BuKey: this.salesOrderJson.bu_id,
            order_part_key: parseInt(this.shipPartKey),
          };
          let buildorderListData = await salesOrderEditService.postBuildData("post", shipDataObj, false, true);
          if (buildorderListData?.Resultset) {
            this.buildPartResultList = buildorderListData.Resultset;
          }
          else {
            this.partBuildPartNum = ""
          }

        }
      }
    },
    // Show Bulilf Part Number Deatils in Dropdown
    showBuildPartDesc(item) {
      this.disableBuildSave = false;
      this.partBuildPartNum = item.part_num;
      this.buildPartResultList = [];
      this.buildPartId = item.part_id;
      this.buildObj = item;
    },
    // on Save Build Part Number
    onClickSaveBuild(buildToQty) {
      this.disableBuildSave = true;
      if (this.$refs.BuildForm?.validate()) {
        let build_id =
          this.temp_order_line.build_orders.build_order.length == undefined || this.temp_order_line.build_orders.build_order == null
            ? 0
            : this.temp_order_line.build_orders.build_order.length + 1;
        let newObj = {
          bo_line_num: build_id,
          bo_qty: buildToQty,
          bo_delete_ok: true,
          bo_edit_ok: true,
          bo_part_id: this.buildObj.part_id,
          bo_part_description: this.buildObj.part_desc,
          bo_part_number: this.buildObj.part_num,
          is_Deleted: false,
          is_Added: true,
          kit_tran_id: 0,
          build_order_ok: true,
        };
        this.temp_order_line.build_orders.build_order.push(newObj);
        this.buildOrderList.push(newObj);
        this.part_num = "";
        this.selectedbuildPart = "";
        this.buildToQty = "";
        this.partBuildPartNum = "";
        this.$refs.BuildForm?.resetValidation();
        this.disableQty = true;
        this.temp_order_line.sh_quantity = 1;
        this.temp_order_line.return_quantity = 1;
      }
    },
    // on Click open the Dialog Dialog of build order tables
    onClickEditBuild(item, buildOrderEditShow) {
      this.disableBuildSave = true;
      if (buildOrderEditShow == "buildOrderEditShow") {
        this.editListFlex = false;
        this.editWaybillShow = false;
        this.showEditBuild = true;
        this.editPartQty = item.bo_qty;
        this.showbuildDelete = item.bo_delete_ok;
        this.showbuildEdit = item.bo_edit_ok;
        this.editPartNum = item.bo_part_number;
        this.editPartDesc = item.bo_part_description;
        this.build_id = item.bo_line_num;
        this.build_Added = item.is_Added;
        this.buildRow = true;
      } else if (buildOrderEditShow == "editWaybillShow") {
        this.buildRow = false;
        this.editListFlex = false;
        this.editWaybillShow = true;
        this.showEditBuild = true;
        this.expectedDelete = item.sn_delete_ok;
        this.expectedEdit = item.sn_edit_ok;
        this.editSerialNum = item.serial_number;
        this.editAssetTag = item.asset_tag;
        this.sequence_number = item.sequence_number;
        this.editProblemDesc = item.problem_description;
        this.editWarrentyStatus = item.warranty_status;
        this.editTrack = item.return_tracking_number;
        this.editReturn_carrier = item.return_carrier;
        this.temp_waybill_id = item.temp_waybill_id;
      } else if (buildOrderEditShow == "editListFlex") {
        this.buildRow = false;
        this.editListFlex = true;
        this.editWaybillShow = false;
        this.showEditBuild = true;
        this.sequence_number = item.temp_flex_id;
        this.list_flex_field_Att = item.flex_code;
        this.list_flex_value = item.flex_data;
      }
    },
    // TO Edit the Build order Update Button
    onClickUpdateBuild(editPartQty, editPartDesc) {
      if (this.$refs.EditBuildForm?.validate()) {
        this.temp_order_line.build_orders.build_order = this.temp_order_line.build_orders.build_order?.map((p) =>
          p.bo_line_num == this.build_id ? { ...p, bo_qty: editPartQty, bo_part_description: editPartDesc } : p
        );
        this.buildOrderList = this.buildOrderList?.map((p) =>
          p.bo_line_num == this.build_id ? { ...p, bo_qty: editPartQty, bo_part_description: editPartDesc } : p
        );
        this.showEditBuild = false;
      }
    },
    // To delete Build order
    deleteBuild() {
      if (this.build_Added) {
        this.disableBuildSave = true;
        this.temp_order_line.build_orders.build_order = this.temp_order_line?.build_orders?.build_order?.filter(
          (x) => x.bo_line_num !== this.build_id
        );
      } else {
        this.temp_order_line.build_orders.build_order.find((v) => v.bo_line_num === this.build_id).is_Deleted = true;
      }
      this.buildOrderList = this.buildOrderList?.filter((x) => x.bo_line_num !== this.build_id);
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
      if (!this.temp_order_line.build_orders.build_order.length) this.disableQty = false;
    },
    // Same as Part to Recive
    async onChangeSameAsShip() {
      if (this.sameAsShip) {
        this.partReturnSearchKey = this.partShipSearchKey;
        this.returnPartNum = this.shipPartName;
        this.returnPartDesc = this.shipPartDesc;
        this.temp_order_line.return_part_number = this.temp_order_line.sh_part_number;
        this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity;
        this.temp_order_line.return_price = this.temp_order_line.sh_price;
        this.temp_order_line.return_part_description = this.temp_order_line.sh_part_description;
        this.temp_order_line.return_part_id = this.temp_order_line.sh_part_id;
      } else {
        this.temp_order_line.return_part_number = "";
        //this.temp_order_line.return_quantity = ""
        this.temp_order_line.return_price = "";
        this.temp_order_line.return_part_description = "";
        this.temp_order_line.return_to_warehouse_id = "";
        this.temp_order_line.return_part_id = "";
        this.partReturnSearchKey = "";
        this.returnPartNum = "";
        this.returnPartDesc = "";
      }
      let RetrunWareObj = {
          part_num: this.temp_order_line.return_part_number,
          order_type: this.salesOrderJson.order_type,
          sotype_key: this.temp_order_line.order_line_type,
          order_bu_key: this.salesOrderJson.bu_id,
          line_id: 0, //Do not Delete
          so_key: 0, //DO not Delete
          UserID: this.userId,
        };
        let shipWarehouseList = await salesService.postShipWareData("post", RetrunWareObj);
        if (shipWarehouseList?.ReturnToWare) {
          this.returnWareList = shipWarehouseList.ReturnToWare?.map((x) => ({
            ware: x.ware,
            ware_id: x.ware_id + "_" + x.bucket,
            bucket: x.bucket,
            into_warebuck: x.into_warebuck,
          }));
        }
    },
    // return to ship
    async returnPartData() {
      this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity;
      if (this.partReturnSearchKey) {
        this.clearPartReturn()
        this.partReturnSearchKey = this.partReturnSearchKey?.toUpperCase();
        if (this.partReturnSearchKey.length > 2) {
          this.showPartRetDetails = true;
          let returnSerachObj = {
            UserId: this.userId,
            SearchStr: this.partReturnSearchKey,
            BuKey: this.salesOrderJson.bu_id,
            order_type: this.salesOrderJson.order_type,
            sotype_key: this.temp_order_line.order_line_type,
            ship_part_key: this.shipPartKey,
            line_key: 0, // Do not Delete
          };
          let returnSearchData = await salesService.postReturnSearch("post", returnSerachObj, false, true);
          if (returnSearchData?.Resultset) this.returnResultList = returnSearchData.Resultset;
          else {
            this.clearPartReturn()
          }
        }
      }
    },
    // Return Part Desc
    async showReturnPartDesc(item) {
      this.partReturnSearchKey = item.part_num;
      this.temp_order_line.return_part_id = item.part_id;
      this.temp_order_line.return_part_number = item.part_num;
      this.temp_order_line.return_part_description = item.part_desc;
      this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity
      this.returnResultList = [];
      let RetrunWareObj = {
        part_num: item.part_num,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id: 0, //Do not Delete
        so_key: 0, //DO not Delete
        UserId: this.userId,
      };
      let shipWarehouseList = await salesService.postShipWareData("post", RetrunWareObj);
      this.temp_order_line.return_price = parseInt(shipWarehouseList.Result1[0].defaultprice);
      if (shipWarehouseList?.ReturnToWare) {
        this.returnWareList = shipWarehouseList.ReturnToWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          into_warebuck: x.into_warebuck,
        }));
      }
    },
    // on Save Waybill
    onClickSerialAdd(serialNum, assetTag, probDesc, selectedWarrentyStatus, trackNum, return_carrier) {
      if (this.$refs.ExpectedSrForm?.validate()) {
        if (this.temp_order_line.expected_serial_number.length <= 0) {
          let expected_ord_seq =
            this.temp_order_line.expected_serial_number.length == undefined || this.temp_order_line.expected_serial_number.length == null
              ? 0
              : this.temp_order_line.expected_serial_number.length;
          this.temp_waybill_id = this.temp_waybill_id + 1;
          let obj = {
            temp_waybill_id: this.temp_waybill_id,
            sequence_number: expected_ord_seq + 1,
            serial_number: serialNum,
            asset_tag: assetTag,
            problem_description: probDesc,
            warranty_status: selectedWarrentyStatus,
            return_tracking_number: trackNum,
            return_carrier: return_carrier,
            sn_edit_ok: true,
            sn_delete_ok: true,
            is_Added: true,
            is_Deleted: false,
            sobcnId: 0,
          };
          this.temp_order_line.expected_serial_number.push(obj);
          this.expected_serial_number_list.push(obj);
          this.serial_num = "";
          this.asset_tag = "";
          this.problem_desc = "";
          this.warr_status = "N";
          this.tracking_num = "";
          this.return_carrier = "";
          this.$refs.ExpectedSrForm?.resetValidation();
        } else {
          let validSerial = {};
          if (!serialNum) validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.return_tracking_number == trackNum);
          else {
            validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.serial_number == serialNum);
          }
          if (!trackNum) validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.serial_number == serialNum);
          else {
            validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.return_tracking_number == trackNum);
          }
          if (!validSerial) {
            let expected_ord_seq =
              this.temp_order_line.expected_serial_number.length == undefined || this.temp_order_line.expected_serial_number.length == null
                ? 0
                : this.temp_order_line.expected_serial_number.length;
            this.temp_waybill_id = this.temp_waybill_id + 1;
            let obj = {
              temp_waybill_id: this.temp_waybill_id,
              sequence_number: expected_ord_seq + 1,
              serial_number: serialNum,
              asset_tag: assetTag,
              problem_description: probDesc,
              warranty_status: selectedWarrentyStatus,
              return_tracking_number: trackNum,
              return_carrier: return_carrier,
              is_Added: true,
              is_Deleted: false,
              sobcnId: 0,
            };
            this.temp_order_line.expected_serial_number.push(obj);
            this.expected_serial_number_list.push(obj);
            this.serial_num = "";
            this.asset_tag = "";
            this.problem_desc = "";
            this.warr_status = "N";
            this.tracking_num = "";
            this.return_carrier = "";
            this.$refs.ExpectedSrForm?.resetValidation();
          } else {
            if (validSerial?.serial_number) {
              let Alert = {
                type: "error",
                isShow: true,
                message: "The Serial number " + serialNum + " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "The Tracking number " + trackNum + " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          }
        }
      }
    },
    // Update waybil
    onClickUpdateWaybill(editSerialNum, editAssetTag, editProblemDesc, editWarrentyStatus, editTrack, return_carrier) {
      if (this.$refs.EditExpectedSrForm?.validate()) {
        this.expectedUpdate = false;
        this.temp_order_line.expected_serial_number = this.temp_order_line.expected_serial_number?.map((p) =>
          p.temp_waybill_id == this.temp_waybill_id
            ? {
              ...p,
              serial_number: editSerialNum,
              asset_tag: editAssetTag,
              problem_description: editProblemDesc,
              warranty_status: editWarrentyStatus,
              return_tracking_number: editTrack,
              return_carrier: return_carrier,
            }
            : p
        );
        this.expected_serial_number_list = this.expected_serial_number_list?.map((p) =>
          p.temp_waybill_id == this.temp_waybill_id
            ? {
              ...p,
              serial_number: editSerialNum,
              asset_tag: editAssetTag,
              problem_description: editProblemDesc,
              warranty_status: editWarrentyStatus,
              return_tracking_number: editTrack,
              return_carrier: return_carrier,
            }
            : p
        );
        this.showEditBuild = false;
        this.$refs.EditExpectedSrForm?.resetValidation();
        this.$refs.EditExpectedSrForm?.reset();
      }
    },
    // Delete Waybill
    deleteWaybill() {
      if (this.temp_order_line.expected_serial_number.is_Added) {
        this.temp_order_line.expected_serial_number = this.temp_order_line?.expected_serial_number?.filter(
          (x) => x.temp_waybill_id !== this.temp_waybill_id
        );
      } else {
        this.expected_serial_number_list = this.expected_serial_number_list?.filter((x) => x.temp_waybill_id !== this.temp_waybill_id);
        this.temp_order_line.expected_serial_number.find((v) => v.temp_waybill_id === this.temp_waybill_id).is_Deleted = true;
      }
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
    },
    // Edit Line List
    //View Line Item Details
    async lineItemsView(item) {    
      if (item.order_line_type == 1) {
        this.lineItemPanel = [0];
      }
      else if (item.order_line_type == 8) {
        this.lineItemPanel = [1];
      }
      else {
        this.lineItemPanel = [0, 1];
      }
      this.enableEdit = true;
      this.showConevertDropDown = false;
      this.shipWareList = [];
      this.returnWareList = [];
      this.shipPartKey = item.sh_part_id;
      if (item.is_Added || item.isEdited) {
        this.temp_order_line = JSON.parse(JSON.stringify(this.salesOrderJson?.order_line?.filter((x) => x.line_number == item.line_number)[0]));
        if (item.isEdited && !this.temp_order_line.is_Converted && !this.temp_order_line.is_FAllocated) {
          this.temp_order_line["showConvertBtn"] = true;
          this.temp_order_line["showForceBtn"] = true;
        } else {
          this.temp_order_line["showConvertBtn"] = false;
          this.temp_order_line["showForceBtn"] = false;
        }
      } else {
        let lineItemData = await salesOrderEditService.getLineItemData("get", this.userId, item.line_id);
        this.temp_order_line = JSON.parse(JSON.stringify(lineItemData.LineItem[0]));
        this.temp_order_line["showConvertBtn"] = true;
        this.temp_order_line["showForceBtn"] = true;

      }
      this.expected_serial_number_list = JSON.parse(JSON.stringify(this.temp_order_line.expected_serial_number))?.filter((x) => x.is_Deleted == false);
      this.buildOrderList = JSON.parse(JSON.stringify(this.temp_order_line.build_orders.build_order))?.filter((x) => x.is_Deleted == false);
      if (this.buildOrderList.length > 0) {
        this.disableQty = true;
      }
      this.showPartDetails = true;
      this.showPartsItem = true;
      this.showLineDelete = true;
      if (this.temp_order_line.is_Converted) {
        this.showConevertDropDown = true;
      }
      this.LineTypeData();
      this.warrentyData();
      this.showPartsItem = true;
      this.disableLine = true;
      this.disablePartShip=!this.temp_order_line.enable_inbound_save
      let obj = this.lineTypeList?.filter((x) => x.so_type_id == item.order_line_type);
      this.showPartShip = obj[0].to_ship == 1 ? true : false;
      this.showPartRet = obj[0].to_return == 1 ? true : false;
      if (obj.so_type_id == 6) {
        this.disablePartRecieve = true;
      }
      if (!this.temp_order_line.is_Edited) {
        this.showPartShip = obj[0]?.to_ship == 1 ? true : false;
        this.showPartRet = obj[0]?.to_return == 1 ? true : false;
      }
      if (this.showPartShip && this.showPartRet) this.disableRetQty = true;
      else if (!this.showPartShip && this.showPartRet) this.disableRetQty = false;
      this.showFlex = true;
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      this.showFlex = true;
      this.edit_line_num = item.line_number;
      this.partShipSearchKey = item.sh_part_number;
      this.shipPartName = item.sh_part_number;
      this.shipPartDesc = item.sh_part_description;
      this.partReturnSearchKey = item.return_part_number;
      this.returnPartNum = item.return_part_number;
      this.returnPartDesc = item.return_part_description;
      //Ship Object
      let shipWareObj = {
        part_num: this.temp_order_line.sh_part_number ? this.temp_order_line.sh_part_number : this.temp_order_line.return_part_number,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id:0, //Do not Delete
        so_key: this.salesOrderJson.so_id, //DO not Delete
        UserId: this.userId,
      };
      let shipWarehouseList = await salesOrderEditService.postShipWareData("post", shipWareObj);
      this.showBuildIcon = shipWarehouseList.Result2[0].enable_build_sheet;
      this.showexpIcon = shipWarehouseList.Result2[0].enable_expected_sn;
      // this.showBuildIcon = true // DO no Delete
      if (shipWarehouseList?.ShipFromWare) {
        this.shipWareList = shipWarehouseList.ShipFromWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          from_warebuck: x.from_warebuck,
          show_text: x.show_text,
          mess_bit: x.mess_bit,
        }));
      }
            if (this.showPartRet && this.temp_order_line?.return_part_number) {
              let returnObj = {
                part_num: this.temp_order_line.return_part_number,
                order_type: this.salesOrderJson.order_type,
                sotype_key: this.temp_order_line.order_line_type,
                order_bu_key: this.salesOrderJson.bu_id,
                line_id: 0, //Do not Delete
                so_key: this.salesOrderJson.so_id, //DO not Delete
                UserId: this.userId,
              };
              let returnWareData = await salesOrderEditService.postShipWareData(
                "post",
                returnObj
              );
              if (returnWareData?.ReturnToWare != "") {
                this.returnWareList = shipWarehouseList.ReturnToWare.map(
                  (x) => ({
                    ware: x.ware,
                    ware_id: x.ware_id + "_" + x.bucket,
                    bucket: x.bucket,
                    into_warebuck: x.into_warebuck,
                  })
                );
              }
            }
      
      this.temp_order_line.sh_from_warehouse_id = this.temp_order_line.sh_from_warehouse_id + "_" + this.temp_order_line.sh_from_inventory_bucket;
      this.temp_order_line.return_to_warehouse_id =
        this.temp_order_line.return_to_warehouse_id + "_" + this.temp_order_line.return_to_inventory_bucket;
      let shippedPartData = this.shipWareList?.filter((x) => x.ware_id == this.temp_order_line.sh_from_warehouse_id);
      this.showStockStatus = shippedPartData[0]?.mess_bit;
      this.stockMessage = shippedPartData[0]?.show_text;
      // Part number API to show the Build Icon or not
      if (!item.is_Added) {
        this.temp_order_line.showConvertBtn = this.temp_order_line.showConvertBtn && this.temp_order_line.enable_convertL;
        this.temp_order_line.showForceBtn = this.temp_order_line.showForceBtn && this.temp_order_line.enable_fallocate;
      } else {
        this.temp_order_line.showConvertBtn = false;
        this.temp_order_line.showForceBtn = false;
      }
      if (this.temp_order_line?.build_orders?.build_order.length > 0) this.showBuildExpand = true;
      else this.showBuildExpand = false;
      if (this.temp_order_line?.expected_serial_number.length > 0) this.showExpectedSr = true;
      // Part number to ship qty validations
      this.temp_order_line["shipTempQty"] = this.temp_order_line.sh_quantity;
      this.temp_order_line["returnTempQty"] = this.temp_order_line.return_quantity;
      this.tempOrderLineBackup = JSON.parse(JSON.stringify(this.temp_order_line));
      if (this.temp_order_line.Picked !== 0 || this.temp_order_line.Shipped !== 0) {
        this.greaterThanValid = true;
        this.greaterVal = Math.max(this.temp_order_line.Picked, this.temp_order_line.Shipped);
        // Rules for Validation
        this.greaterThanRule.push(
          (v) => parseInt(v) > 0 || "Qty should be greater than 0",
          (v) => parseInt(v) <= this.temp_order_line.sh_quantity || `Qty should be less than equals to +"  ${this.temp_order_line.sh_quantity}`,
          (v) => parseInt(v) >= this.greaterVal || "Qty cannot be less than " + this.greaterVal
        );
      }

      // this is for Part number to receive
      if (!this.showPartShip && this.showPartRet) {
        this.greaterThanValidRet = true;
        this.greaterValRet = this.temp_order_line.Rcvd;
        // Rules for Validation
        this.greaterThanRuleRet.push(
          (v) => parseInt(v) > 0 || "Qty should be greater than 0",
          (v) => parseInt(v) > this.greaterValRet || "Qty cannot be less than " + this.greaterValRet
        );
      }
      this.temp_order_line["order_line_text"] = item.order_line_text;
    },
    onChangeShipping() {
      this.salesOrderJson.signature_required = false
      this.salesOrderJson.saturday_delivery = false;
      let CarrierFlags = this.outboundList?.filter((x) => x.sl_id == this.salesOrderJson.shipping_priority);
      this.disableSignature = CarrierFlags[0].sig_required;
      this.disableSatDel = CarrierFlags[0].sat_delivery;
    },
    //onClick Delete
    onClickLineDelete() {
      this.deleteBuildDialog = true;
      this.deleteItem = true;
    },
    // on Convert line ITem
    onConvertLine() {
      this.lineItemPanel = [0, 1];
      this.showForceDialog = true;
      this.convertMsg = true;
      let convertData = this.lineTypeList?.filter((x) => x.so_type_id == this.temp_order_line.converted_to);
      this.showPartShip = Boolean(convertData[0].to_ship);
      this.showPartRet = Boolean(convertData[0].to_return);
      this.disableRetQty = Boolean(convertData[0].to_disable_quantity);
      if (!this.showPartShip) {
        this.clearPartShip();
        this.showPartShip = false;
        this.lineItemPanel = [0, 1];
      } else if (!this.showPartRet) {
        this.clearPartReturn();
        this.showPartRet = false;
        this.lineItemPanel = [0, 1];
      }
    },
    //Delete Line Item
    deleteLineItem() {
      this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
      this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
      if (this.temp_order_line.is_Added) {
        this.salesOrderJson.order_line = this.salesOrderJson?.order_line?.filter((x) => x.line_number !== this.edit_line_num);
      } else if (!this.temp_order_line.is_Added && !this.temp_order_line.is_Deleted) {
        this.salesOrderJson.order_line = this.salesOrderJson?.order_line?.filter((x) => x.line_number !== this.edit_line_num);
        this.temp_order_line.is_Deleted = true;
        this.temp_order_line.is_Edited = true;
        this.salesOrderJson.order_line.push(this.temp_order_line);
      } else {
        this.temp_order_line.is_Deleted = true;
        this.temp_order_line.is_Edited = true;
        this.salesOrderJson.order_line.push(this.temp_order_line);
      }
      this.salesOrderJson.order_list = this.salesOrderJson?.order_list?.filter((x) => x.line_number !== this.edit_line_num);
      this.deleteBuildDialog = false;
      this.showPartsItem = false;
      this.totalRecords = this.salesOrderJson.order_line.length;
    },
    // Edit Line ITem
    editLineItem() {
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      let temp_objIdx = this.salesOrderJson.order_list?.findIndex((v) => v.line_number === this.temp_order_line.line_number);
      if (!~temp_objIdx) {
        return;
      }
      let temp_obj = this.salesOrderJson.order_list[temp_objIdx];
      if (this.temp_order_line.converted_to) {
        this.temp_order_line.is_Converted = true;
        this.salesOrderJson.order_line = this.salesOrderJson?.order_line?.filter((x) => x.line_number !== this.edit_line_num);
        let convertData = this.lineTypeList?.filter((x) => x.so_type_id == this.temp_order_line.converted_to);
        temp_obj.order_line_text = convertData[0]?.so_desc;
      }
      if (this.temp_order_line.is_Added) {
        this.salesOrderJson.order_line = this.salesOrderJson?.order_line?.filter((x) => x.line_number !== this.edit_line_num);
        this.temp_order_line.is_Edited = false;
        this.temp_order_line.is_Deleted = false;
        this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
        this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
        temp_obj.return_to_warehouse = this.temp_order_line.return_to_warehouse
        temp_obj.return_to_inventory_bucket = this.temp_order_line.return_to_inventory_bucket
        temp_obj.sh_from_warehouse = this.temp_order_line.sh_from_warehouse
        temp_obj.sh_from_inventory_bucket = this.temp_order_line.sh_from_inventory_bucket

        this.salesOrderJson.order_line.push(temp_obj);
      } else {
        this.temp_order_line.is_Deleted = false;
        this.temp_order_line.is_Added = false;
        this.temp_order_line.is_Edited = true;
        this.salesOrderJson.order_line = this.salesOrderJson?.order_line?.filter((x) => x.line_number !== this.edit_line_num);
        this.salesOrderJson.order_line.push(this.temp_order_line);
      }
      if (this.showPartShip && this.showPartRet) {
        if (this.$refs.PartShipForm?.validate() && this.$refs.PartRecieveForm?.validate()) {
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description

          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity);
          temp_obj.Open_Shipments = temp_obj.quantity - temp_obj.Shipped;
          temp_obj.Open_Receipts = temp_obj.quantity - temp_obj.Rcvd;
          temp_obj.isEdited = true;
          if (this.temp_order_line.converted_to) {
            let convertData = this.lineTypeList?.filter((x) => x.so_type_id == this.temp_order_line.converted_to);
            temp_obj.order_line_text = convertData[0]?.so_desc;
          }
          temp_obj.return_part_number = this.temp_order_line.return_part_number
          temp_obj.return_part_description = this.temp_order_line.return_part_description
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showPartsItem = false;
          temp_obj.return_to_warehouse = this.temp_order_line.return_to_warehouse
          temp_obj.return_to_inventory_bucket = this.temp_order_line.return_to_inventory_bucket
          temp_obj.sh_from_warehouse = this.temp_order_line.sh_from_warehouse
          temp_obj.sh_from_inventory_bucket = this.temp_order_line.sh_from_inventory_bucket
          this.salesOrderJson.order_list.splice(temp_objIdx, 1, temp_obj);
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm?.validate()) {
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity);
          temp_obj.Open_Shipments = temp_obj.quantity - temp_obj.Shipped;
          temp_obj.return_part_number = "";
          temp_obj.return_part_description = "";
          temp_obj.Open_Receipts = 0;
          temp_obj.isEdited = true;
          if (this.temp_order_line.converted_to) {
            let convertData = this.lineTypeList?.filter((x) => x.so_type_id == this.temp_order_line.converted_to);
            temp_obj.order_line_text = convertData[0]?.so_desc;
          }
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showPartsItem = false;
          this.salesOrderJson.order_list.splice(temp_objIdx, 1, temp_obj);
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm?.validate()) {
          temp_obj.return_part_number = this.temp_order_line.return_part_number
          temp_obj.return_part_description = this.temp_order_line.return_part_description
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity);
          temp_obj.Open_Receipts = temp_obj.quantity - temp_obj.Rcvd;
          temp_obj.sh_part_number = "";
          temp_obj.sh_part_description = "";
          temp_obj.Open_Shipments = 0;
          temp_obj.isEdited = true;
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity);
          if (this.temp_order_line.converted_to) {
            let convertData = this.lineTypeList?.filter((x) => x.so_type_id == this.temp_order_line.converted_to);
            temp_obj.order_line_text = convertData[0]?.so_desc;
          }
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showPartsItem = false;
          temp_obj.return_to_warehouse = this.temp_order_line.return_to_warehouse
          temp_obj.return_to_inventory_bucket = this.temp_order_line.return_to_inventory_bucket
          temp_obj.sh_from_warehouse = this.temp_order_line.sh_from_warehouse
          temp_obj.sh_from_inventory_bucket = this.temp_order_line.sh_from_inventory_bucket
          this.salesOrderJson.order_list.splice(temp_objIdx, 1, temp_obj);
        }
      }
    },
    //Cancel Line Item
    cancelLineItems() {
      this.clearPartItem();
      this.showPartsItem = false;
      this.editLinePart = true;
    },
    // On Click Add Part List Item
    submitLineItems() {

      this.temp_order_line.Allocated = 0;
      this.temp_order_line.Picked = 0;
      this.temp_order_line.Shipped = 0;
      this.temp_order_line.Rcvd = 0;
      this.buildOrderList.length > 0 ? (this.temp_order_line.build_order_ok = true) : (this.temp_order_line.build_order_ok = false);
      this.temp_order_line.enable_save = true;
      let order_line_num =
        this.salesOrderJson.order_list.length == null || this.salesOrderJson.order_list.length == undefined
          ? 0
          : this.salesOrderJson.order_list.length;
      this.temp_order_line.is_FAllocated = false;

      if (this.showPartShip && this.showPartRet) {
        if (this.$refs.PartShipForm?.validate() && this.$refs.PartRecieveForm?.validate()) {
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.temp_order_line.is_Added = true;
          this.temp_order_line.is_Edited = false;
          this.temp_order_line.is_Deleted = false;
          this.temp_order_line.quantity = this.temp_order_line.sh_quantity;
          this.temp_order_line.Open_Shipments = this.temp_order_line.quantity - this.temp_order_line.Shipped;
          this.temp_order_line.Open_Receipts = this.temp_order_line.quantity - this.temp_order_line.Rcvd;
          this.salesOrderJson.order_list.push(this.temp_order_line);
          this.salesOrderJson.order_line.push(this.temp_order_line);

          this.showPartsItem = false;
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm?.validate()) {
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.temp_order_line.is_Added = true;
          this.temp_order_line.is_Edited = false;
          this.temp_order_line.is_Deleted = false;
          this.temp_order_line.quantity = this.temp_order_line.sh_quantity;
          this.temp_order_line.Open_Receipts = 0;
          this.temp_order_line.Open_Shipments = this.temp_order_line.quantity - this.temp_order_line.Shipped;
          this.salesOrderJson.order_list.push(this.temp_order_line);
          this.salesOrderJson.order_line.push(this.temp_order_line);
          this.showPartsItem = false;
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm?.validate()) {
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.temp_order_line.is_Edited = false;
          this.temp_order_line.is_Deleted = false;
          this.temp_order_line.quantity = this.temp_order_line.return_quantity;
          this.temp_order_line.Open_Shipments = 0;
          this.temp_order_line.Open_Receipts = this.temp_order_line.quantity - this.temp_order_line.Rcvd;
          this.temp_order_line.is_Added = true;
          this.salesOrderJson.order_list.push(this.temp_order_line);
          this.salesOrderJson.order_line.push(this.temp_order_line);
          this.showPartsItem = false;
        }
      }
      this.totalRecords = this.salesOrderJson.order_list.length;
      this.validateLineType();
    },
    // on Change LIne Typeh
    onChangeLineType(value) {
      this.disablePartShip=false
      this.temp_order_line.enable_save=true
      if (value.order_line_type == 1) {
        this.lineItemPanel = [0];
      }
      else if (value.order_line_type == 8) {
        this.lineItemPanel = [1];
      }
      else {
        this.lineItemPanel = [0, 1];
      }
      this.enableEdit = false;
      this.disableLine = true;
      let obj = this.lineTypeList?.filter((x) => x.so_type_id == value);
      this.showPartShip = obj[0]?.to_ship == 1 ? true : false;
      this.showPartRet = obj[0]?.to_return == 1 ? true : false;
      this.showFlex = true;
      this.temp_order_line.order_line_text = obj[0]?.so_desc;
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      this.disableRetQty = obj[0]?.to_disable_quantity == 1 ? true : false;
      this.disablePartRecieve = obj[0].to_disable_ReturnSel == 1 ? true : false;
      if (this.disablePartRecieve) {
        this.partReturnSearchKey = this.partShipSearchKey;
        this.temp_order_line.return_part_number = this.temp_order_line.sh_part_number;
        this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity;
        this.temp_order_line.return_part_description = this.temp_order_line.sh_part_description;
        this.temp_order_line.return_part_id = this.temp_order_line.sh_part_id;
      }
      if (value == 2 || value == 6) {
        this.temp_order_line.sh_quantity = 1;
      }
      // if part return is true then only return rma gets filled otherwise its empty
      if (this.showPartRet) this.temp_order_line.return_rma = this.salesOrderJson.rma;
      else this.temp_order_line.return_rma = "";
    },
    // On Chnage Part SHip WareHouse DropDOwn
    onPartShipChange(warehouse_id) {
      let partShipObj = this.shipWareList?.filter((x) => x.ware_id == warehouse_id);
      this.temp_order_line.sh_from_inventory_bucket = partShipObj[0]?.bucket;
      this.temp_order_line.sh_from_warehouse = partShipObj[0]?.ware;
      this.temp_order_line.sh_from_warehouse_id = partShipObj[0]?.ware_id;
      this.showStockStatus = partShipObj[0].mess_bit;
      this.stockMessage = partShipObj[0].show_text;
    },
    //Reset Data to API Call JSON
    resetFunction() {
      this.salesOrderJson = JSON.parse(JSON.stringify(this.salesOrderDetails.Result[0]));
      this.convertTime()
      this.showOrderedDate = false;
      this.showShipByDate = false;
      this.showEndDate = false;
      this.showNewHireDate = false;
      this.$nextTick(() => {
        this.showOrderedDate = true;
        this.showShipByDate = true;
        this.showNewHireDate = true;
      });
    },
    //Add BookMark
    async addBookmark() {
      let resp = await salesOrderEditService.addBookMark("get", this.userId, this.salesOrderId, true);
      if (resp?.Result) {
        this.bookmark = true;
        this.showActionsList = this.showActionsList?.filter((item) => item.Id != "SO-BookMark");
        this.showActionsList.unshift({
          Id: "SO-BookMark-Remove",
          Icon: "mdi mdi-bookmark-outline",
          FriendlyName: "Remove Bookmark",
        });
      } else this.bookmark = false;
      bus.$emit("bookmark", this.bookmark);
    },
    //remove BookMark
    async removeBookmark() {
      let resp = await salesService.getDeleteBookmarkSO("get", this.userId, this.salesOrderId, true);
      if (resp?.Result) {
        this.bookmark = false;
        this.showActionsList = this.showActionsList?.filter((item) => item.Id != "SO-BookMark-Remove");
        this.showActionsList.unshift({
          Id: "SO-BookMark",
          Icon: "mdi mdi-bookmark",
          FriendlyName: "Add Bookmark",
        });
      } else this.bookmark = true;
      bus.$emit("bookmark", this.bookmark);
    },
    // Update API
    async postSoUpdate() {
      if (this.$refs.salesOrderForm?.validate()) {
        if (this.$refs.CarrierLevlInfoForm?.validate() && this.$refs.addressForm?.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.final_submit = true;
          this.clearCustAddressData()
          this.salesOrderJson.ordered_date = Utility.convertLocalToUTC(this.salesOrderJson.ordered_date)
          this.salesOrderJson.requested_ship_date = this.salesOrderJson.requested_ship_date ?
            this.salesOrderJson.requested_ship_date : "";
          this.salesOrderJson.new_hire_date = this.salesOrderJson.new_hire_date ?
            Utility.convertLocalToUTC(this.salesOrderJson.new_hire_date) : "";
          let final_save = {
            UserId: this.userId,
            json: JSON.stringify(this.salesOrderJson),
          };
          this.salesOrderJson.ordered_date = this.salesOrderJson.ordered_date.substr(0, 10);
          this.salesOrderJson.requested_ship_date = this.salesOrderJson.requested_ship_date.substr(0, 10);
          this.salesOrderJson.new_hire_date = this.salesOrderJson.new_hire_date.substr(0, 10);
          let saveOrder = await salesOrderEditService.postSoUpdate("post", final_save, true);
          if (saveOrder?.Result[0]?.Message) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.$router.push({
              name: "salesOrderDetails",
              params: {
                id: btoa(this.salesOrderId),
              },
            });
          }
        } else {
          if (!this.$refs.addressForm?.validate() && !this.$refs.CarrierLevlInfoForm?.validate()) {
            let invalidField = this.$refs.addressForm.$children?.find((e) => !e.valid);
            if (invalidField)
              invalidField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            if (this.returnReq) this.disableReturn = false;
            else this.disableShip2 = false;
            this.disableShippingSection = false;
          } else if (!this.$refs.addressForm?.validate()) {
            let invalidField = this.$refs.addressForm.$children?.find((e) => !e.valid);
            if (invalidField)
              invalidField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            if (this.returnReq) this.disableReturn = false;
            else this.disableShip2 = false;
          } else {
            this.disableShippingSection = false;
            let invalidField = this.$refs.CarrierLevlInfoForm.$children?.find((e) => !e.valid);
            if (invalidField)
              invalidField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
          }
        }
      } else {
        let invalidField = this.$refs.salesOrderForm.$children?.find((e) => !e.valid);
        if (invalidField)
          invalidField.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
      }
    },
    // Delete SO
    deleteClicked() {
      this.deleteBuildDialog = true;
      this.soDelete = true;
    },
    //Delete API Call
    async deleteSOClicked() {
      let deleteObj = {
        so_id: this.salesOrderId,
        current_status: "X",
        cancel_reason: this.cancelReason,
        userId: this.userId,
      };
      let delRes = await salesOrderEditService.postSoCancel("post", deleteObj, true);
      if (delRes?.Result) {
        this.deleteReasonDialog = false;
        this.final_submit = true;
        this.isEditing = false;
        this.deleteBuildDialog = false;
        this.$router.push(`/sales-order`);
      }
    },
    //Hold SO
    async postSoHold() {
      this.disableHoldBtn = true;
      this.showForceDialog = false;
      let deleteObj = {
        so_id: this.salesOrderId,
        current_status: "H",
        cancel_reason: "",
        userId: this.userId,
      };
      let resp = await salesOrderEditService.postSoDelete("post", deleteObj, true);
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (resp?.Result) {
        this.salesOrderJson.message = resp.Result[0].displaymessage;
        this.holdMsg = false;
        this.hold = true;
        this.disableHoldBtn = false;
        this.showActionsList = this.showActionsList?.filter((item) => item.Id != "SO-Hold");
        this.showActionsList.unshift({
          Id: "SO-Hold",
          Icon: "mdi mdi-play-outline",
          FriendlyName: "SO Release",
        });
        this.salesOrderDetails = await salesOrderEditService.getSalesOrderDetails("get", this.userId, this.salesOrderId);
        // Assigning the values we get from API to the Form JSON we created
        this.salesOrderJson = JSON.parse(JSON.stringify(this.salesOrderDetails.Result[0]));

        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else this.hold = false;
      bus.$emit("hold", this.hold);
      this.releaseMsg = false;
    },
    //Release Hold
    async postSoHoldRelease() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.disableHoldBtn = true;
      this.showForceDialog = false;
      let deleteObj = {
        so_id: this.salesOrderId,
        current_status: "",
        cancel_reason: "",
        userId: this.userId,
      };
      let resp = await salesOrderEditService.postSoDelete("post", deleteObj, true);
      if (resp?.Result) {
        this.hold = false;
        this.releaseMsg = false;
        this.disableHoldBtn = false;
        this.showActionsList = this.showActionsList?.filter((item) => item.Id != "SO-Release");
        this.showActionsList.unshift({
          Id: "SO-Hold",
          Icon: "mdi mdi-pause-box-outline",
          FriendlyName: "SO Hold",
        });
        this.salesOrderDetails = await salesOrderEditService.getSalesOrderDetails("get", this.userId, this.salesOrderId);
        // Assigning the values we get from API to the Form JSON we created
        this.salesOrderJson = JSON.parse(JSON.stringify(this.salesOrderDetails.Result[0]));
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else this.hold = true;
      bus.$emit("hold", this.hold);
      this.holdMsg = false;
    },
    //Force Allocate
    OnClickForce() {
      this.showForceDialog = false;
      this.temp_order_line.is_FAllocated = true;
      this.temp_order_line.is_Edited = true;
      this.temp_order_line.showForceBtn = false;
      this.temp_order_line.showConvertBtn = false;
      this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
      this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
      this.showPartsItem = false;
      this.forceMsg = false;
      this.salesOrderJson.order_list.find((v) => v.line_number === this.temp_order_line.line_number).isEdited = true;
      this.salesOrderJson.order_line.push(this.temp_order_line);
    },
    // Reset Line Data
    resetLineData() {
      if (this.showLineDelete) this.temp_order_line = JSON.parse(JSON.stringify(this.tempOrderLineBackup));
      else this.clearPartItem();
    },
    // onClick Picked
    async onClickedHyperLink(value, item) {
      this.showLinkDialog = true;
      this.selectedLink = value;
      if (value === "Picked") {
        let pickedData = await salesOrderEditService.getSalesOrderPickedData("get", this.salesOrderId, item.line_number, this.userId);
        this.pickedDataList = pickedData.Data;
        this.pickedDataList?.forEach((element) => {
          element.Picked = Utility.convertESTToLocal(element.Picked);
        });
      } else if (value === "Received") {
        let receivedData = await salesOrderEditService.getSalesOrderReceivedData("get", this.salesOrderId, item.line_number, this.userId);
        this.receivedDataList = receivedData.Data;
      } else if (value === "Shipped") {
        let shippedData = await salesOrderEditService.getSalesOrderShippedData("get", this.salesOrderId, item.line_number, this.userId);
        this.shippedDataList = shippedData.Data;
      }
    },
    // get Inbound grid Data
    async getInboundGridData() {
      let resp = await salesOrderEditService.getInboundData("get", 1, this.userId, this.salesOrderId, 0);
      this.inboundDataList = resp?.Inbound;
      this.inboundDataList?.forEach((element) => {
        element.retrieved = Utility.convertESTToLocal(element.created_date);
      });
    },
    // Get outbound Grid Data
    async getOutboundGridData() {
      let resp = await salesOrderEditService.getInboundData("get", 2, this.userId, this.salesOrderId, 0);

      if (resp != undefined && resp?.result != "NA") {
        this.outboundDataList = resp?.Outbound;
        this.outboundDataList?.forEach((element) => {
          element.created_date = Utility.convertESTToLocal(element?.created_date);
          element.retrieved = element?.retrieved ? Utility.convertESTToLocal(element?.retrieved) : "";
        });
      }
    },
    async getMessageData(msgId) {
      this.onClickDetailsView("outboundLog", msgId);
    },
    onClickDetailsView(text, item) {
      //console.log("text", text, "item", item)
      this.showDialogDetailsBox = true;
      if (text == "InboundJSON") {
        this.dialogBoxHeader = "View Inbound Request JSON";
        this.displayData = item.request_json;
      } else if (text == "InboundXML") {
        this.dialogBoxHeader = "View Inbound Request XML";
        this.displayData = item.request_xml.replaceAll(">", ">\n");
      } else if (text == "OutboundXML") {
        this.dialogBoxHeader = "View Outbound Request XML";
        this.displayData = item.replaceAll(">", ">\n");
      } else if (text == "OutboundJSON") {
        this.dialogBoxHeader = "View Outbound Request JSON";
        this.displayData = item;
      } else if (text == "outboundLog") {
        this.outboundData.push(item);
        this.dialogBoxHeader = "View Outbound Response";
        this.showOutboundLog = true;
      }
    },
    outboundOrderLine() {
      this.salesOrderJson.order_list?.forEach((element) => {
        this.lineTypeList?.forEach((ele) => {
          if (ele.so_type_id == element.order_line_type) {
            element["outboundPresent"] = ele.to_ship;
          }
        });
      });
    },
    convertTime() {
      this.salesOrderJson = JSON.parse(JSON.stringify(this.salesOrderDetails.Result[0]));

      this.salesOrderJson.cdate = this.salesOrderJson?.cdate
        ? Utility.convertESTToLocal(this.salesOrderJson.cdate)
        : Utility.convertESTToLocal(new Date());
      this.salesOrderJson.udate = this.salesOrderJson?.udate
        ? Utility.convertESTToLocal(this.salesOrderJson.udate)
        : Utility.convertESTToLocal(new Date());
      this.hold = this.salesOrderJson.is_hold;
      this.salesOrderJson.ordered_date = this.salesOrderJson?.ordered_date
        ? Utility.convertESTToLocal(this.salesOrderJson.ordered_date)
        : Utility.convertESTToLocal(new Date());
      if (this.salesOrderJson.new_hire_date == "1900-01-01T00:00:00" || !this.salesOrderJson.new_hire_date)
        this.salesOrderJson.new_hire_date = ""
      else {
        this.salesOrderJson.new_hire_date =
          Utility.convertESTToLocalDate(this.salesOrderJson?.new_hire_date)
      }
      if (this.salesOrderJson.requested_ship_date == "1900-01-01T00:00:00" || !this.salesOrderJson?.requested_ship_date) {
        this.salesOrderJson.requested_ship_date = ""
      }
      else {
        this.salesOrderJson.requested_ship_date =
          Utility.convertESTToLocalDateSO(this.salesOrderJson?.requested_ship_date)
      }
    }
  },
  components: {
    breadcrumbComp,
    addressComp,
    BackToTop,
  },
};
